<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img
                  v-if="(!isVerticalMenuCollapsed || isMouseHovered) && appLogo"
                  :src="appLogo"
                  alt="logo"
                />
                <!-- <b-img v-else :src="appLogoImageCollapsed" alt="logo" /> -->
              </span>
            </b-link>
          </li>

          <li class="nav-item nav-toggle">
            <b-link
              :style="colors"
              class="nav-link modern-nav-toggle custom-theme"
            >
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>

    
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg, BButton } from "bootstrap-vue";
import { provide, computed } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import store from "@/store";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BButton,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      color: "#407e3d",
      imageBaseUrl: process.env.VUE_APP_APIURL,
    };
  },
  created() {},
  computed: {
    appLogo() {
      return store.getters["app/customLogo"]
        ? this.imageBaseUrl + "/images/" + store.getters["app/customLogo"]
        : this.appLogoImage;
    },
    colors() {
      return {
        "--color": store.getters["app/themeColor"]
          ? store.getters["app/themeColor"]
          : this.color,
      };
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage, appLogoImageCollapsed } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      // Skin
      skin,
      // App Name
      appName,
      appLogoImage,
      appLogoImageCollapsed,
    };
  },
};
</script>
<style>
.nav-link.modern-nav-toggle.custom-theme,
.nav-link.modern-nav-toggle.custom-theme svg {
  color: var(--color) !important;
}
</style>
<style lang="scss">

@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
