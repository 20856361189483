import translations from "@/translations"
export default [
  {
    header: 'Web.Generic.Navigation.Header.Supplier',
    routes: [
      {
        title: 'Web.Generic.Navigation.SupplierHome',
        route: 'supplier-home',
        icon: 'HomeIcon',
      },
      /*
      {

      {
        title: 'Navigate.Tenders',
        route: 'supplier-tenders',
        icon: 'BookIcon',
      },
        title: 'Navigate.Orders',
        route: 'supplier-orders',
        icon: 'TrelloIcon',
      },
      */
      {
        title: 'Web.Generic.Navigation.SupplierUsers',
        route: 'supplier-users',
        icon: 'UsersIcon',
      },
      /*
      {
        title: "Navigate.Supplier.ForwardRules",
        route: 'suppliers.forward-rules',
        icon: 'CornerUpRightIcon',
      },
      */
      {
        title: 'Web.Generic.Navigation.SupplierCompletedOrders',
        route: 'supplier-orders.completed',
        icon: 'DatabaseIcon',
      },
      {
        title: 'Web.Generic.Navigation.SupplierTransportVehicles',
        route: 'suppliers.orders.transport-vehicles',
        icon: 'TruckIcon'
      },
      {
        title: 'Transporter',
        route: 'suppliers.orders.transports',
        icon: 'TruckIcon',
      }
    ]
  },
  {
    header: "",
    routes: [
      {
        title: 'Web.Generic.Navigation.ConcernHome',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Web.Generic.Navigation.Infocards',
        route: 'infocards.list',
        icon: 'LayoutIcon',
      },
      {
        title: 'Filer',
        route: 'file-drive.index',
        icon: 'FileIcon',
      }
    ]
  },
  {
    header: 'Web.Generic.Navigation.Header.Ordering',
    routes: [
      {
        title: 'Web.Generic.Navigation.Ordering',
        route: 'ordering.index',
        icon: 'TruckIcon',
      },
    ]
  },
  {
    header: 'Web.Generic.Navigation.Header.Financials',
    routes: [
      {
        title: 'Web.Generic.Navigation.Invoices',
        route: 'economics.invoices.list',
        icon: 'FileTextIcon',
      },
      {
        title: 'Web.Generic.Navigation.InvoiceInspections',
        route: 'economics.invoice-inspections.list',
        icon: 'PocketIcon',
      },
      {
        title: 'Web.Generic.Navigation.Simulations',
        route: 'economics.simulations.list',
        icon: 'PieChartIcon',
      },
      {
        title: 'Web.Generic.Navigation.AgreementBasis',
        route: 'economics.agreementBasis',
        icon: 'BookIcon',
      },

    ]
  },
  {
    header: 'Web.Generic.Navigation.Header.Reporting',
    routes: [
      {
        title: 'Web.Generic.Navigation.AmountsReporting',
        route: 'economics.reporting.amounts',
        icon: 'FileIcon',
      },
      {
        title: 'Web.Generic.Navigation.PostingReporting',
        route: 'economics.reporting.postings',
        icon: 'FileIcon',
      },
      {
        title: 'Web.Generic.Navigation.DrivingReporting',
        route: 'economics.reporting.drivings',
        icon: 'TruckIcon',
      },
      {
        title: 'Web.Generic.Navigation.DataInsights',
        route: 'data-insights.home',
        icon: 'DatabaseIcon',
      }
    ]
  },
  {
    header: 'Web.Generic.Navigation.Header.SystemAdmin',
    routes: [
      {
        title: 'Web.Generic.Navigation.Users',
        route: 'users.index',
        icon: 'UsersIcon',
      },
      {
        title: 'Web.Generic.Navigation.BackgroundData',
        route: 'background-index',
        icon: 'EditIcon',
      },
      {
        title: 'Web.Generic.Navigation.Theme',
        route: 'theme-index',
        icon: 'PenToolIcon',
      },
      {
        title: 'Web.Generic.Navigation.Integrations',
        route: 'integrations',
        icon: 'LinkIcon',
      },
      {
        title: 'Web.Generic.Navigation.CreationWizzard',
        route: 'CreateInfoCardUtil',
        icon: 'ZapIcon'
      },
      {
        title: 'Web.Generic.Navigation.WeightStationsRegistrations',
        route: 'weight-stations.registrations',
        icon: 'ServerIcon'

      },
      {
        title: 'Web.Generic.Navigation.ArchivedInfocards',
        route: 'infocards.archived',
        icon: 'ArchiveIcon'
      },
    ]
  },
  {
    header: 'Web.Generic.Navigation.Header.Directory',
    routes: [
      {
        title: 'Web.Generic.Navigation.DirectoryUsers',
        route: 'user-admin',
        icon: 'UserIcon',
      },
      {
        title: 'Web.Generic.Accounts',
        route: 'directory.accounts.index',
        icon: 'UsersIcon'
      }
    ]
  }
]
