<template>
  <ul v-if="account && this.account.type == 'Directory'">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in getMenu('Directory')"
      :key="item.Header"
      :item="item"
      class="custom-theme"
      :style="colors"
    >
    </component>
  </ul>
  <ul v-else-if="account && this.account.type == 'Supplier'">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in getMenu('Supplier')"
      :key="item.Header"
      :item="item"
      class="custom-theme"
      :style="colors"
    >
    </component>
  </ul>
  <ul v-else-if="account">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in getMenu()"
      :key="item.Header"
      :item="item"
      class="custom-theme"
      :style="colors"
    >
    </component>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import authHelper from "@/auth";
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      color: "#407e3d",
    };
  },
  created() {},
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
  computed: {
    ...mapGetters({ account: "appUser/getCurrentAccountScope" }),
    colors() {
      return {
        "--color": store.getters["app/themeColor"]
          ? store.getters["app/themeColor"]
          : this.color,
      };
    },
  },
  methods: {
    getMenu(type) {
      let filtedItems = [];
      let currentRoles = [];
      const routes = this.$router.options.routes;
      const role = authHelper.getRole();
      currentRoles.push(role);
      if (role == "SuperAdmin" || role == "SystemAdmin") {
        currentRoles.push(authHelper.getRoles().Admin);
        currentRoles.push(authHelper.getRoles().Supplier);
      }

      this.items.forEach((element) => {
        let navs = [];
        const header = element.header;

        if (header) navs.push({ header: header });

        element.routes.forEach((router) => {
          const route = this.findRoute(routes, router.route);

          if (
            !route ||
            !route.meta ||
            route.meta.closed ||
            route.meta.allowAnonymous ||
            (this.isMobile() && !route.meta.mobile) ||
            (!this.isMobile() && route.meta.mobileOnly)
          )
            return;

          if (!route.meta.roles && !route.meta.allowAnonymous) {
            navs.push(router);
            return;
          }

          if (route.meta.roles) {
            const intersection = currentRoles.filter((element) =>
              route.meta.roles.includes(element)
            );

            if (intersection.length <= 0) return;
          }

          if (type && route.meta.concernType != type) return;
          
          if (
            route.meta.concernType &&
            type == undefined &&
            (route.meta.concernType == "Directory" ||
              route.meta.concernType == "Supplier")
          )
            return;

          navs.push(router);
        });
        if (navs.length > 1) filtedItems = filtedItems.concat(navs);
      });

      return filtedItems;
    },
    findRoute(routes, searchRoute) {
      let result = undefined;
      routes.forEach((route) => {
        if (route.name == searchRoute) {
          result = route;
          return;
        } else if (route.children) {
          let searchResult = this.findRoute(route.children, searchRoute);
          if (searchResult) result = searchResult;
          return;
        }
      });
      return result;
    },
  },
};
</script>

<style>
.custom-theme.nav-item:not(.active) svg {
  color: var(--color) !important;
}

[dir="ltr"] .main-menu.menu-light .navigation > li.active.custom-theme > a {
  background: var(--color) !important;
}
</style>