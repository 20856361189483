<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler :style="colors" class="d-none d-lg-block" />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        v-if="userProfile != null"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ accountScope.name }}
              <span v-if="accountScope.cvr != null">
                - {{ accountScope.cvr }}</span
              >
            </p>
            <span class="user-status">
              <span>{{ accountScope.address }} </span>
              <span> {{ userProfile.name }} </span>
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            :text="userProfile.initials"
          >
          </b-avatar>
        </template>
        <template v-if="directoriesList.length > 0">
          <b-dropdown-group
            :header="$t('Generic.SwitchAccount')"
            class="account-switcher"
          >
            <template v-for="directory in directoriesList">
              <b-dropdown-item
                :class="{ active: directory.id == currentAccontId }"
                :key="directory.id"
                @click="swapAccount(directory)"
                class="bg-light"
              >
                <span
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    font-weight-bold
                  "
                >
                  {{ directory.name }}
                  <span>
                    <feather-icon
                      icon="GlobeIcon"
                      v-b-tooltip.hover.right="'Directory'"
                    />
                    <feather-icon
                      class="ml-1"
                      v-if="directory.id == currentAccontId"
                      icon="CheckIcon"
                    />
                  </span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                :v-show="shouldShowAccountList(account)"
                :class="{ active: account.id == currentAccontId }"
                v-for="account in directoryConcerns(directory.id)"
                :key="account.id"
                @click="swapAccount(account)"
              >
                <span class="d-flex align-items-center justify-content-between">
                  {{ account ? account.name : "" }}
                  <feather-icon
                    v-if="account.id == currentAccontId"
                    icon="CheckIcon"
                  />
                </span>
              </b-dropdown-item>
            </template>
          </b-dropdown-group>
        </template>
        <template v-else>
          <b-dropdown-group
            v-if="accountsList.length > 1"
            id="dropdown-group-1"
            :header="$t('Generic.SwitchAccount')"
          >
            <b-dropdown-item
              :class="{ active: account.id == currentAccontId }"
              v-for="account in accountsList"
              :key="account.id"
              @click="swapAccount(account)"
            >
              <span class="d-flex align-items-center justify-content-between">
                {{ account ? account.name : "" }}
                <feather-icon
                  class="ml-1"
                  v-if="account.id == currentAccontId"
                  icon="CheckIcon"
                />
              </span>
            </b-dropdown-item>
          </b-dropdown-group>
        </template>
        <b-dropdown-divider v-if="accountsList.length > 1"></b-dropdown-divider>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logOut">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("Generic.Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  BLink,
  BNavbarNav,
  BDropdown,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownGroup,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { VBTooltip } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BAvatar,
    BDropdownGroup,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      color: "#407e3d",
      concernService: null,
    };
  },
  computed: {
    ...mapGetters({
      userProfile: "appUser/getCurrentProfile",
      accountScope: "appUser/getCurrentAccountScope",
    }),
    accountsList() {
      return this.userProfile.accounts;
    },
    directoriesList() {
      return this.accountsList.filter(a => a.type == 'Directory')
    },
    currentAccontId() {
      return this.accountScope.id;
    },
    colors() {
      return {
        "--color": this.$store.getters["app/themeColor"]
          ? this.$store.getters["app/themeColor"]
          : this.color,
      };
    },
  },
  methods: {
    ...mapActions({
      logOut: "appUser/logOut",
      switchAccountAsync: "appUser/switchAccountScopeAsync",
    }),
    directoryConcerns(directoryId) {
      return this.accountsList.filter((a) => a.directoryId == directoryId);
    },
    async swapAccount(account) {
      await this.switchAccountAsync(account.id);
    },
    shouldShowAccountList(account)
    {
      if(account.id == this.currentAccontId) return true;
      if(this.accountScope.directoryId == account.directoryId) return true;
      return false;
    }
  },
};
</script>
<style lang="scss">
.bookmark-wrapper a svg {
  color: var(--color) !important;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: unset;
  min-width: 300px;
}
#dropdown-group-1 li.active {
  background-color: rgba(64, 126, 61, 0.12);
  color: #407e3d;
}
.user-status {
  display: flex;
  flex-direction: column;
  align-items: end;
  > span {
    margin-bottom: 1px;
  }
}
.account-switcher {
  .active .dropdown-item {
    background-color: rgba(64, 126, 61, 0.12);
    color: #407e3d;
  }
}
</style>